import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";

import academieGrenoble from "../images/customers/academie-grenoble.jpg";
import benoitBertet from "../images/customers/benoit-bertet.jpg";
import clubLouisiane from "../images/customers/club-louisiane.jpg";
import codofil from "../images/customers/codofil.jpg";
import consulatUs from "../images/customers/consulat-us.jpg";
import coora from "../images/customers/coora.jpg";
import ford from "../images/customers/ford.jpg";
import gem from "../images/customers/gem.jpg";
import grenoble from "../images/customers/grenoble.jpg";
import inovallee from "../images/customers/inovallee.jpg";
import isere from "../images/customers/isere.jpg";
import inp from "../images/customers/inp.jpg";
import institutFrancais from "../images/customers/institut-francais.jpg";
import iveco from "../images/customers/iveco.jpg";
import magal from "../images/customers/magal.jpg";
import ra from "../images/customers/ra.jpg";
import schneider from "../images/customers/schneider.jpg";
import seyssins from "../images/customers/seyssins.jpg";

const Customers = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t("customers.title")}</h2>
      <div className="row mx-5 mb-5">
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={clubLouisiane}
            alt="club louisiane France"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={schneider}
            alt="Schneider"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={coora}
            alt="Coora"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={codofil}
            alt="Codofil"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={consulatUs}
            alt="Consulat des États-Unis"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={magal}
            alt="Magal"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={benoitBertet}
            alt="Benoit Bertet"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={ford}
            alt="Ford"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={gem}
            alt="Grenoble École de Management"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={inp}
            alt="INP"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={inovallee}
            alt="Innovallée"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={institutFrancais}
            alt="Institut Français"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={isere}
            alt="Isère conseil général"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={iveco}
            alt="Iveco"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={grenoble}
            alt="Grenoble"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={ra}
            alt="région Rhône Alpes"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={seyssins}
            alt="Seyssins"
          />
        </div>
        <div className="col-lg-2 col-sm-4 img-responsive align-self-center">
          <Image
            height="100px"
            className="img-fluid img-rotate"
            src={academieGrenoble}
            alt="Académie de Grenoble"
          />
        </div>
      </div>
    </>
  );
};

export default Customers;
