import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const DynamicBreadcrumb = ({ pageTitle, prestations }) => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h1 className="mt-4 mb-3">{pageTitle}</h1>
      <Breadcrumb>
        <Breadcrumb.Item href="/">{t("breadcrumb.home")}</Breadcrumb.Item>
        {prestations && (
          <Breadcrumb.Item active>{t("navbar.prestations")}</Breadcrumb.Item>
        )}
        <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default DynamicBreadcrumb;
