import { useTranslation } from "react-i18next";

const AgileProfileDevelopment = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="card mb-3">
        <div className="card-body">
          <p className="lead">
            {t("prestation.agile_profile_development.title_article")}
            <br />
          </p>
          <hr className="my-4" />
          <p className="card-text">
            {t("prestation.agile_profile_development.list")}
          </p>
          <ul>
            <li>{t("prestation.agile_profile_development.list_1")}</li>
            <li>{t("prestation.agile_profile_development.list_2")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AgileProfileDevelopment;
