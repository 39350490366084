import { useTranslation } from "react-i18next";
import ProcessCom from "../images/process-com.png";

const Tools = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t("training_modules.title")}</h2>
      <img
        src={ProcessCom}
        alt="modules de formations adaptables"
        className="img-responsive"
      />
    </>
  );
};

export default Tools;
