import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import miniLogo from "../images/mini-logo.png";

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container">
        <img src={miniLogo} height="26px" alt="mini logo" />
        <a
          className="navbar-brand big-title text-uppercase text-manjari "
          href="/"
        >
          {t("navbar.title")}
        </a>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              {location.pathname === "/" ? (
                <AnchorLink className="nav-link" href="#presentation">
                  {t("navbar.presentation")}
                </AnchorLink>
              ) : (
                <NavLink className="nav-link" exact to="/">
                  {t("navbar.presentation")}
                </NavLink>
              )}
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdownPortfolio"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navbar.prestations")}
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdownPortfolio"
              >
                <NavLink
                  to="/auralite-serendipite"
                  className="dropdown-item text-body"
                >
                  {t("navbar.auralite_serendipite")}
                </NavLink>
                <NavLink to="/process-com" className="dropdown-item text-body">
                  {t("navbar.process_com")}
                </NavLink>
                <NavLink
                  to="/agile-profile-Developpement"
                  className="dropdown-item text-body"
                >
                  {t("navbar.agile_profile_development")}
                </NavLink>
                <NavLink
                  to="/agile-profile-inventory"
                  className="dropdown-item text-body"
                >
                  {t("navbar.agile_profile_inventory")}
                </NavLink>
              </div>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link">Tarifs</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">Publications</a>
            </li>
            <li className="nav-item">
              <a className="nav-link">FAQ</a>
            </li> */}
            <li className="nav-item">
              <NavLink to="/sas-aventures" className="nav-link ">
                {t("navbar.sas_aventures")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link ">
                {t("navbar.contact")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
