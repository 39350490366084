import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_fr from "./translation.json";

const root = document.getElementById('root');

i18n
  .use(initReactI18next)
  .init({
    resources: {
			fr: {
				translation: common_fr
			}
		},
    lng: "fr",
    interpolation: {
      escapeValue: false
    }
  }, function(err, t) {
		root.innerHTML = i18n.t('key');
	});

	export default i18n;
