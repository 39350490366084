import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import flyer from "../images/flyer-voiron.png";
import flyerPDF from "../images/flyer-voiron.pdf";
import Icon from "./Icon";

const Contact = () => {
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <div className="mb-3">
        <div className="row ">
          <div className="col">
            {t("sas_aventures.form")}
            <a
              href="https://forms.gle/KrEFwQ8jT6r6XxmC7"
              target="_blank"
              rel="noreferrer"
            >
              https://forms.gle/KrEFwQ8jT6r6XxmC7
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {t("sas_aventures.email")}
            <a href="mailto:contact@coachinactions.com">{t("contact.email")}</a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {t("sas_aventures.phone")}
            <Icon name="phone" />
            <a href="tel:0680662919">{t("contact.phone")}</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.form")}</h4>
        </div>
        <div className="col">
          <h5>
            <a
              href="https://forms.gle/KrEFwQ8jT6r6XxmC7"
              target="_blank"
              rel="noreferrer"
            >
              https://forms.gle/KrEFwQ8jT6r6XxmC7
            </a>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.email")}</h4>
        </div>
        <div className="col">
          <h5>
            <a href="mailto:contact@coachinactions.com">{t("contact.email")}</a>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.phone")}</h4>
        </div>
        <div className="col">
          <h5>
            <Icon name="phone" />
            <a href="tel:0680662919">{t("contact.phone")}</a>
          </h5>
        </div>
      </div>
    </>
  );
};

const SASAventures = () => {
  return (
    <div className="container justify-content-md-center">
      <Contact />
      <div className="row">
        <div className="col">
          <a href={flyerPDF} target="_blank" rel="noreferrer">
            <img
              src={flyer}
              alt="flyer"
              className="img-fluid rounded mb-4 w-75 d-block mx-auto"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SASAventures;
