import { useTranslation } from "react-i18next";
import PrestationBlock from "./PrestationBlock";

const PrestationPresentation = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t("prestation.title")}</h2>
      <p>{t("prestation.description")}</p>
      <p className="font-italic">{t("prestation.devis")}</p>
      <div className="row mt-4">
        <div className="col-lg-3 mb-3">
          <PrestationBlock
            title={t("prestation.auralite_serendipite.title")}
            description={t("prestation.auralite_serendipite.description")}
            url="auralite-serendipite"
            btnColor="btn-primary"
          />
        </div>
        <div className="col-lg-3 mb-3">
          <PrestationBlock
            title={t("prestation.process_com.title")}
            description={t("prestation.process_com.description")}
            url="process-com"
            btnColor="btn-success"
          />
        </div>
        <div className="col-lg-3 mb-3">
          <PrestationBlock
            title={t("prestation.agile_profile_development.title")}
            description={t("prestation.agile_profile_development.description")}
            url="agile-profile-Developpement"
            btnColor="btn-warning"
          />
        </div>
        <div className="col-lg-3 mb-3">
          <PrestationBlock
            title={t("prestation.agile_profile_inventory.title")}
            description={t("prestation.agile_profile_inventory.description")}
            url="agile-profile-inventory"
            btnColor="btn-danger"
          />
        </div>
      </div>
    </>
  );
};

export default PrestationPresentation;
