import { useTranslation } from "react-i18next";

const AgileProfileInventory = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="card mb-3">
        <div className="card-body">
          <p className="card-text">
            {t("prestation.agile_profile_inventory.list")}
          </p>
          <ul>
            <li>{t("prestation.agile_profile_inventory.list_1")}</li>
            <li>{t("prestation.agile_profile_inventory.list_2")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AgileProfileInventory;
