import { useTranslation } from "react-i18next";

const Legal = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      {t("mentions.subtitle")}

      <div className="card my-4">
        <div className="card-body">
          <h3>
            <b>ÉDITEUR</b>
          </h3>
          <div className="card-text">
            <h4>COACH'IN Actions</h4>
            <p>Société par Actions Simplifiée au capital de 5 000 Euros</p>
            <ul>
              <li>
                <b>SIREN</b> : 501 763 122 RCS Grenoble
              </li>
              <li>
                <b>Siège social</b> : 14 rue docteur Bordier, 38 100 Grenoble,
                FRANCE
              </li>
              <li>
                <b>Directeur de publication</b> : Alain ENDEZOUMOU
              </li>
            </ul>
            <h4>Nous contacter</h4>
            <ul>
              <li>
                <b>Courrier</b> : Alain ENDEZOUMOU, 14 rue docteur Bordier, 38
                100 Grenoble, FRANCE
              </li>
              <li>
                <b>Téléphone</b> : (+33)4 76 88 85 91
              </li>
              <li>
                <b>E-mail</b> :
                <a href="mailto:contact@coachinactions.com">
                  {" "}
                  contact@coachinactions.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-body">
          <h3>
            <strong>HÉBERGEMENT</strong>
          </h3>
          <p>
            Le présent site est hebergé par la société{" "}
            <b>
              <a href="https://www.online.net/">ONLINE</a>
            </b>
            , dont les informations de contact sont :<br />
            <i>ONLINE SAS BP 438 75366 PARIS CEDEX 08 FRANCE</i>
          </p>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <h3>
            <b>UTILISATION</b>
          </h3>
          <p>
            Le présent site et l'ensemble de son contenu (textes, photos,
            vidéos, logos...) sont la propriété exclusive de COACH'IN ACTIONS
            SARL. Toute reproduction, représentation, adaptation, intégrale ou
            partielle, est strictement interdite.
          </p>
          <p>
            La visite du présent site ne nécessite pas que vous décliniez votre
            identité ni que vous fournissiez d'informations personnelles.
            <br /> Nous ne vous demanderons jamais d'informations strictement
            confidentielles (telles que vos mots de passe, coordonnées
            bancaires) par e-mail ou formulaire via ce site internet.
          </p>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <h3>
            <b>CRÉDITS</b>
          </h3>
          <p>
            Le site internet est basé sur un template Bootstrap sous{" "}
            <b>licence MIT</b>.
          </p>
          <p>Les images utilisées proviennent de : </p>
          <ul>
            <li>
              <a href="/">Accueil</a> -{" "}
              <a
                href="https://bloculus.com/process-communication-les-fondamentaux/"
                target="_blank"
                rel="noreferrer"
              >
                Process Com
              </a>{" "}
              Image
            </li>
            <li>
              <a href="/">Accueil</a> -{" "}
              <a
                href="https://fr.pngtree.com/"
                target="_blank"
                rel="noreferrer"
              >
                Diamants
              </a>{" "}
              Images
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Legal;
