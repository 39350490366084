import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AgileProfileDevelopment from "./components/prestations/AgileProfileDevelopment";
import AgileProfileInventory from "./components/prestations/AgileProfileInventory";
import AuraliteSerendipite from "./components/prestations/AuraliteSerendipite";
import DynamicBreadcrumb from "./components/DynamicBreadcrumb";
import Footer from "./components/Footer";
import Legal from "./components/Legal";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ProcessCom from "./components/prestations/ProcessCom";
import SASAventures from "./components/SASAventures";
import Contact from "./components/Contact";

const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.countapi.xyz/hit/coachinactions.com/visits");
    xhr.responseType = "json";
    xhr.send();
  }, []);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Router>
        <div className="wrapper flex-grow-1">
          <Navbar />
          <Switch>
            <Route path="/auralite-serendipite">
              <DynamicBreadcrumb
                pageTitle={t("navbar.auralite_serendipite")}
                prestations
              />
              <AuraliteSerendipite />
            </Route>
            <Route path="/process-com">
              <DynamicBreadcrumb
                pageTitle={t("navbar.process_com")}
                prestations
              />
              <ProcessCom />
            </Route>
            <Route path="/agile-profile-Developpement">
              <DynamicBreadcrumb
                pageTitle={t("navbar.agile_profile_development")}
                prestations
              />
              <AgileProfileDevelopment />
            </Route>
            <Route path="/agile-profile-inventory">
              <DynamicBreadcrumb
                pageTitle={t("navbar.agile_profile_inventory")}
                prestations
              />
              <AgileProfileInventory />
            </Route>
            <Route path="/sas-aventures">
              <DynamicBreadcrumb pageTitle={t("navbar.sas_aventures")} />
              <SASAventures />
            </Route>
            <Route path="/contact">
              <DynamicBreadcrumb pageTitle={t("navbar.contact")} />
              <Contact />
            </Route>
            <Route path="/mentions-legales">
              <DynamicBreadcrumb pageTitle={t("mentions.title")} />
              <Legal />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
