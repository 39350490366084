import { useTranslation } from "react-i18next";
import logo from "../images/logo.png";

const Presentation = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center">
        <h1 className="font-weight-bold mt-3">{t("welcome_title")}</h1>
        <h2 className="mt-2">{t("work_subtitle")}</h2>
        <h2 className="font-italic">{t("orga_subtitle")}</h2>
      </div>
      <section id="presentation">
        <hr />
        <div className="row">
          <div className="col-lg-4 text-center align-self-center">
            <img src={logo} alt="logo" className="img-fluid rounded mb-4" />
          </div>
          <div className="col-lg-8 align-self-center text-justify">
            <h2>{t("presentation.title")}</h2>
            <p>
              Coach certifié professionnel en accompagnement individuel et
              d’équipes de Grenoble Ecole de Management, je vous accompagne dans
              tous les métiers de la VOI(E)X (Conférences, séminaires,
              enseignements, pratique du chant amateur et professionnel, prise
              de paroles en public...) en plus du coaching conventionnel et
              classique (reconversion professionnelle, gestion d’équipes,
              cohésion de groupes...)
            </p>
            <p>
              Je mets à votre disposition des outils expérimentés de manière
              empirique et professionnelle depuis plus de 25 ans pour :
            </p>
            <ul>
              <li>
                une meilleure conscience de soi de laquelle découlera une
                confiance en soi.
              </li>
              <li>
                une meilleure gestion de soi et des relations avec les autres.
              </li>
              <li>la prévention du burn out.</li>
              <li>
                la vision, la redéfinition, ou le réajustement de votre parcours
                personnel et professionnel.
              </li>
              <li>une meilleure cohésion de votre équipe.</li>
              <li>une meilleure communication avec vos différents publics.</li>
              <li>un partage de vision collective.</li>
            </ul>
            À ce jour, 2800 personnes formées autour de la voi(e)x depuis 2001.
          </div>
        </div>
        <div className="row">
          <div className="col-lg align-self-center text-justify">
            <p>Je vous accompagne :</p>
            <ul>
              <li>
                en coaching individuel à partir d’une problématique définie.
              </li>
              <li>en coaching d’équipe à partir d’un contexte défini.</li>
            </ul>
            <p>
              Après une cartographie de personnalités Process Com, Agile Profile
              Inventory et/ou Agile Profile Developpement, nous définissons
              ensemble le type d’accompagnement adapté à vous ou à votre
              contexte :
            </p>
            <ul>
              <li>soit par un coaching classique individuel ou d’équipes.</li>
              <li>
                soit par la mise en place de modules de formation
                professionnelle ajustés à votre besoin et à vos attentes.
              </li>
            </ul>
            <p>
              En option, une cartographie de vos émotions habituelles est
              également possible par la photographie pendant les séances de
              coaching. Elle vous aidera à travailler sur votre image. Cette
              cartographie de vos émotions habituelles vous permettra aussi de
              réduire l’écart entre votre auto perçu et l’image réelle que vous
              projetez.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Presentation;
