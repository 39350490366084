import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer">
      <div className="bg-dark py-5 text-white text-center">
        {t("footer")}
        <br />
        <a href="/mentions-legales" className="link">
          {t("mentions.footer")}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
