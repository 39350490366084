import { useTranslation } from "react-i18next";

const AuraliteSerendipite = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="card mb-3">
        <div className="card-body">
          <h1 className="display-4">
            {t("prestation.auralite_serendipite.title_article")}
          </h1>
          <p className="lead">
            {t("prestation.auralite_serendipite.subtitle")}
            <br />
          </p>
          <hr className="my-4" />
          <p className="card-text">
            {t("prestation.auralite_serendipite.text")}
            <b>{t("prestation.auralite_serendipite.haka")}</b>
            {t("prestation.auralite_serendipite.text_2")}
            <b>{t("prestation.auralite_serendipite.reborn")}</b>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuraliteSerendipite;
