import { useTranslation } from "react-i18next";
import Icon from "./Icon";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="container justify-content-md-center">
      <div className="row">
        <div className="col-sm-8">
          <iframe
            width="100%"
            height="400px"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="google maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.543976280248!2d5.727372315598519!3d45.176083579098524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478af49536bdbf13%3A0x5a156b3d39747677!2s14%20Rue%20Dr%20Bordier%2C%2038100%20Grenoble!5e0!3m2!1sfr!2sfr!4v1580389892850!5m2!1sfr!2sfr"
          ></iframe>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-header">
              <h3>{t("contact.contact_me")}</h3>
            </div>
            <div className="card-body">
              <p className="card-text">
                Alain ENDEZOUMOU
                <br /> 14 rue docteur Bordier <br /> 38100 Grenoble
                <br /> FRANCE
              </p>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <Icon name="phone" />
                  <a href="tel:0476888591">04 76 88 85 91</a>
                  <br />
                </li>
                <li className="list-group-item">
                  <Icon name="phone" />
                  <a href="tel:0680662919">{t("contact.phone")}</a>
                </li>
                <li className="list-group-item">
                  <Icon name="linkedin" />
                  <a href="https://www.linkedin.com/in/alain-endezoumou-a9730021">
                    alain-endezoumou
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-3">
        <div className="col-sm border text-center p-3">
          <h5>{t("contact.questions")}</h5>
          <Icon name="mail" />
          <a href="mailto:contact@coachinactions.com">{t("contact.email")}</a>
        </div>
        <div className="col-sm border text-center p-3">
          <h5>{t("contact.devis")}</h5>
          <Icon name="mail" />
          <a href="mailto:glc@coachinactions.com">glc@coachinactions.com</a>
        </div>
        <div className="col-sm border text-center p-3">
          <h5>{t("contact.partners")}</h5>
          <Icon name="mail" />
          <a href="mailto:direction@coachinactions.com">
            direction@coachinactions.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
