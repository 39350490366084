import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const PrestationBlock = ({ title, description, url, btnColor }) => {
  const { t } = useTranslation();

  return (
    <div className="card h-100">
      <h4 className="card-header text-center">{title}</h4>
      <div className="card-body">
        <p className="card-text text-justify">{description}</p>
      </div>
      <div className="card-footer text-center">
        <NavLink to={url} className={"font-weight-bold btn " + btnColor}>
          {t("prestation.discover")}
        </NavLink>
      </div>
    </div>
  );
};

export default PrestationBlock;
