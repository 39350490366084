import CarrouselImage from "./CarrouselImage";
import Customers from "./Customers";
import Presentation from "./Presentation";
import PrestationPresentation from "./PrestationPresentation";
import Tools from "./Tools";
import TrainingModules from "./TrainingModules";

const Home = () => {
  return (
    <>
      <CarrouselImage />
      <div className="container">
        <Presentation />
        <hr />
        <PrestationPresentation />
        <hr />
        <div className="row">
          <div className="col-sm">
            <Tools />
          </div>
          <div className="col-sm text-center">
            <TrainingModules />
          </div>
        </div>
        <hr />
        <Customers />
      </div>
    </>
  );
};

export default Home;
