import { useTranslation } from "react-i18next";

const Tools = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <h2>{t("tools.title")}</h2>
      <ul>
        <li>{t("tools.first")}</li>
        <li>{t("tools.second")}</li>
        <li>{t("tools.third")}</li>
        <li>{t("tools.four")}</li>
        <li>{t("tools.five")}</li>
        <li>{t("tools.six")}</li>
      </ul>
      {t("tools.list")}
    </div>
  );
};

export default Tools;
