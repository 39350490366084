import image from "../images/image.jpg";
import diamant from "../images/diamant.jpg";
import mountain from "../images/mountain.jpg";

const CarrouselImage = () => {
  return (
    <header>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          <div
            className="carousel-item active"
            style={{
              backgroundImage: `url(${image})`,
              backgroundPosition: "left-top",
            }}
          >
            <div className="carousel-caption d-md-block">
              <div className="backblack">
                <h3>Alain ENDEZOUMOU</h3>
                <span className="text-center subtitle">
                  Découvrons ensemble et approfondissons votre Voi(e)x <br />
                  de la CONSCIENCE DE SOI à la CONFIANCE EN SOI.
                </span>
              </div>
            </div>
          </div>
          <div
            className="carousel-item"
            style={{
              backgroundImage: `url(${diamant})`,
              backgroundPosition: "left-top",
            }}
          >
            <div className="carousel-caption-top d-md-block text-center">
              <h3>Ensemble, modelons votre diamant intérieur.</h3>
            </div>
          </div>
          <div
            className="carousel-item"
            style={{
              backgroundImage: `url(${mountain})`,
              backgroundPosition: "left-top",
            }}
          >
            <div className="carousel-caption-bottom d-md-block text-center">
              <h3>
                Ensemble, faisons émerger cette pépite qui sommeille en vous !
              </h3>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </header>
  );
};

export default CarrouselImage;
