import { useTranslation } from "react-i18next";
import illustration from "../../images/process-com-illustration.png";

const ProcessCom = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="card mb-3">
        <div className="card-body">
          <h1 className="display-4">
            {t("prestation.process_com.title_article")}
          </h1>
          <p className="lead">
            Elle permet de réaliser votre cartographie de personnalités. <br />
            Cette cartographie vous permet de prendre conscience de l’alignement
            de votre <b>"Je, Moi, et Moi-même"</b>.
          </p>
          <p>À savoir :</p>
          <ul>
            <li>
              votre personnalité de base, celle avec laquelle vous êtes né(e)
            </li>
            <li>
              vos phases vécues, la ou les personnalités que vous avez développé
              pendant les périodes de traumatismes de votre vie
            </li>
            <li>
              et votre phase actuelle, la personnalité que vous mettez en avant
              par vos motivations actuelles
            </li>
          </ul>
          <p>La même chose s’applique à votre entreprise ou votre équipe.</p>
          <p>
            Il est important de savoir que chacun(e) de nous est un immeuble
            interne à 6 étages.
          </p>
          <p>Chaque étage est une personnalité portant un nom précis.</p>
          <div className="text-center">
            <img src={illustration} alt="process communication" width="60%" />
          </div>
          <hr className="my-4" />
          <p>
            Quand notre ascenseur intérieur tombe en panne, nous entrons dans
            une situation de stress qui nous conduit vers des escaliers en
            direction du sous sol et de la cave. Nous voyons ainsi apparaître
            des signes avant coureurs de la dépression et/ ou de burn out.
          </p>
          <p>
            Nous mécommuniquons avec notre entourage, nous ne comprenons pas nos
            propres décisions, nous sommes en position de vie -/+, nous nous
            dévalorisons, nous remettons en question notre choix de métier.
          </p>
          <p>
            Je mets alors à votre disposition un ensemble d’outils qui vous
            permettront de prendre conscience de la source de ces désordres et
            vous pourrez ainsi élaborez un plan d’action à la hauteur de vos
            aspirations.
          </p>
          <hr />
          <div className="text-center m-4 font-weight-bold">
            <h5 className="text-danger">{t("sign.brand")}</h5>
            {t("sign.subtitle")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessCom;
