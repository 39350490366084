import linkedin from "../images/linkedin.svg";
import mail from "../images/mail.svg";
import phone from "../images/phone.svg";

const Icon = ({ name }) => {
  let image = null;
  let alt = "icon";

  switch (name) {
    case "phone":
      image = phone;
      alt = "phone icon";
      break;
    case "linkedin":
      image = linkedin;
      alt = "linkedin icon";
      break;
    case "mail":
      image = mail;
      alt = "mail icon";
      break;
    default:
      image = phone;
      alt = "phone icon";
      break;
  }

  return <img src={image} alt={alt} height="14px" className="mr-2" />;
};

export default Icon;
